import React, { useState, useEffect } from "react"
import { Helmet } from "react-helmet"
import Layout from "../../components/layout"
import ColoredHeader from "../../components/coloredHeader"
import BookSVG from "../../components/Icons/Book"
import FloatingSearch from "../../components/floatingSearch"
import sustainability_header from "../../../static/background_images/sustainability_header.jpg"
import ScrollToTop from "../../components/ScrollToTop"
import EEOIcon from "../../../static/icons/EEO.js"
import MembershipIconImage from "../../../static/images/Membership.png"
import DataSheet from "../../../static/icons/DataSheet.js"
import Presentation from "../../../static/icons/Presentation.js"
import EOG_2022_EEO from "../../../static/documents/EEO1_2022_Consolidated.pdf"
import { Row, Column, SectionBar } from "./_components"
import SocialImage from "../../../static/sustainability/2023/SocialImage.png"
import EnvironmentImage from "../../../static/sustainability/2023/EnvironmentImage.png"
import GovernanceImage from "../../../static/sustainability/2023/GovernanceImage.png"
import TWP2023 from "../../../static/sustainability/2023/TWP-EOGRESOURCES-2023.png"
import {
  faArrowCircleDown as faArrowDown,
  faArchive,
  // faArrowCircleUp as faArrowUp,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button } from "@material-ui/core"

const TRADE_ASSOCIATION_MEMBERSHIP_DUES =
  "/documents/2023 Trade Association Memberships and Dues.pdf"

const EOG_2022_Sustainability_TEAR_SHEET =
  "https://eogresources.s3.us-east-2.amazonaws.com/EOG_2022_Data_Sheet.pdf"

const EOG_2022_Sustainability =
  "https://eogresources.s3.us-east-2.amazonaws.com/EOG_2022_Sustainability_Report.pdf"

const EOG_2022_Sustainability_Presentation =
  "https://eogresources.s3.us-east-2.amazonaws.com/EOG_2022_Sustainability_Presentation.pdf"

function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  })

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }
    window.addEventListener("resize", handleResize)
    handleResize()
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  return windowSize
}

export default function Sustainability() {
  const themeColor = "#00c853"

  const [letterIsExpanded, setLetterIsExpanded] = React.useState(false)

  const { width } = useWindowSize()
  const isMobile = width < 768

  console.log({ width, isMobile })

  return (
    <Layout>
      <Helmet>
        <title>Sustainability - EOG Resources, Inc.</title>
        <link
          rel="preload"
          href="/fonts/Gotham-Book.woff2"
          as="font"
          type="font/woff2"
          crossorigin
        />
        <link
          rel="preload"
          href="/fonts/Gotham-Book.woff"
          as="font"
          type="font/woff"
          crossorigin
        />
        <link
          rel="preload"
          href="/fonts/Gotham-Bold.woff2"
          as="font"
          type="font/woff2"
          crossorigin
        />
        <link
          rel="preload"
          href="/fonts/Gotham-Bold.woff"
          as="font"
          type="font/woff"
          crossorigin
        />
      </Helmet>
      <ColoredHeader
        bgImageSRC={sustainability_header}
        title="SUSTAINABILITY"
        header="An Integrated Approach"
        overlayColor1="#27a4a4"
        overlayColor2={themeColor}
        lineColor="rgb(0, 200, 83)"
      />
      <div className="max-width-container absolute desktop-only">
        <FloatingSearch clear />
      </div>
      <div className="page head sustainability-page">
        <section>
          <Row>
            <Column thirds={2}>
              <h2>Dear Stakeholders,</h2>
              <p>
                <strong>
                  EOG’s value proposition and energy transition strategy are one
                  in the same – to be among the lowest cost, lowest emissions
                  and highest return producers, playing a significant role in
                  the long-term future of energy.
                </strong>
              </p>{" "}
              <p>
                To meet the dual challenge of the energy transition - supplying
                reliable, affordable energy while addressing climate risk -
                requires investments in technology to reduce emissions across
                all forms of energy supply, not the elimination of energy
                sources. The growth of future demand will require energy
                supplies from all sources, including oil and natural gas. EOG’s
                robust inventory of premium resources, those that deliver at
                least a 30% well-level return<sup>i</sup> at $40 oil and $2.50
                natural gas, positions us to play a significant role in the
                long-term future of energy.
              </p>{" "}
              {letterIsExpanded ? null : (
                <Button
                  className="letter-expander"
                  variant={isMobile && "outlined"}
                  role="button"
                  onClick={() => setLetterIsExpanded(x => !x)}
                >
                  {!isMobile && (
                    <FontAwesomeIcon
                      icon={faArrowDown}
                      style={{ width: 30, height: 30, marginRight: "1rem" }}
                      className="default-icon"
                    />
                  )}
                  <div>Read the full Letter to Stakeholders</div>
                </Button>
              )}
              {letterIsExpanded && (
                <React.Fragment>
                  <p>
                    Our success is tied to our execution: investing with
                    discipline in a resilient high return portfolio of diverse
                    assets, leading safety and environmental performance, and
                    utilizing technology and employee innovation to offer
                    sustainable value creation through both short and long
                    industry cycles.
                  </p>
                  <p>
                    We are committed to disciplined investment in new
                    technologies and infrastructure aimed at lowering our
                    environmental impact – and we measure that investment like
                    all others at EOG: what are the expected returns and impact
                    to improving our company. Whether it’s investments in new
                    equipment for water reuse; technology such as our
                    proprietary continuous methane monitoring solution iSenseSM;
                    or closed loop gas capture, a first of its kind project;
                    each of these investments are aimed at reducing our
                    environmental impact while also creating shareholder value.
                  </p>
                  <p>
                    This year, our report reflects on our significant progress
                    in lowering our emissions footprint. We highlight several
                    accomplishments below in the context of supporting our
                    broader energy transition strategy:
                    <em>
                      Stakeholder Engagement and Collaboration, Board Oversight,
                      Transparent Disclosure, and Operational Emissions Targets
                      and Net Zero Ambition.
                    </em>
                  </p>{" "}
                  <h3>Stakeholder Engagement and Collaboration</h3>{" "}
                  <p>
                    Stakeholder engagement is a core value at EOG. In meeting
                    with shareholders, policy makers, landowners, or industry
                    partners, we seek to collaborate with and provide
                    transparency to our stakeholders.
                  </p>{" "}
                  <p>
                    Our stakeholders are diverse, and it follows that they hold
                    diverse priorities and viewpoints. However, what is commonly
                    recognized across stakeholders is that a successful
                    long-term energy solution cannot compromise energy security
                    nor overlook energy poverty; that certain sectors will
                    continue to rely on oil and gas even under the most
                    ambitious energy transition scenarios; that while oil and
                    natural gas will be a part of the future energy mix,
                    emissions reduction is critical; and finally, that lowering
                    the emissions intensity of global energy supply will require
                    new technology, the ability to scale, and rapid deployment.
                  </p>{" "}
                  <h3>Board Oversight</h3>{" "}
                  <p>
                    As the governing body of EOG, our board of directors
                    oversees execution of our value proposition, including our
                    commitment to lowering our environmental impact. Our board
                    is regularly updated on our progress, including reports from
                    management on our safety and environmental performance,
                    which is considered in evaluating employee performance and
                    compensation, including executive compensation. This year,
                    the board further increased the weighted ESG performance
                    factor tied to executive annual short-term incentive
                    compensation to reflect the importance of our overall
                    environmental and safety program.
                  </p>{" "}
                  <h3>Transparent Disclosure</h3>{" "}
                  <p>
                    Accurate, credible data and transparent disclosure help
                    drive accountability throughout the company – from financial
                    and environmental performance metrics for our board and
                    stakeholders to evaluate the company, to real-time
                    operational data for front-line employees to make better,
                    faster decisions.
                  </p>{" "}
                  <p>
                    Our continued commitment to transparency of ESG-related
                    disclosures is informed by feedback we receive from
                    stakeholder engagement. In line with this commitment, in
                    past reports we have provided additional disclosures, such
                    as expanding our reported spill and recovery efforts to
                    cover all spills greater than one barrel.
                  </p>{" "}
                  <p>
                    This year, recognizing that financial institutions are
                    reviewing their portfolios in many sectors to determine
                    alignment to their climate goals we have included Scope 3
                    emissions from the use of sold products in the report. While
                    the calculation is a broad estimate, we have included a
                    description of the inputs and methodology used to provide a
                    more transparent metric for our stakeholders.{" "}
                  </p>
                  <h3>Operational Emissions Targets and Net Zero Ambition</h3>{" "}
                  <p>
                    More than two years ago we announced our net zero ambition
                    for Scope 1 and 2 emissions by 2040. The path to our net
                    zero ambition includes specific and measurable reduction
                    targets for both GHG and methane emissions intensities. We
                    are pleased to announce we achieved our initial GHG
                    emissions intensity and methane emissions percentage targets
                    for 2025, three years ahead of schedule. We have also met
                    our zero routine flaring goal in 2023, well ahead of our
                    2025 target and significantly ahead of the world bank
                    initiative which strives to attain zero routine flaring by
                    2030. Achieving all three near-term targets is the result of
                    our employees’ commitment to lower operational emissions
                    through efficiency and innovation throughout each of our
                    asset areas.
                  </p>{" "}
                  <p>
                    Our net zero ambition is a multifaceted approach that
                    includes efforts to reduce, capture, and offset carbon
                    equivalent emissions. And our progress the last several
                    years is due to our focus on reducing emissions from our
                    operations, which is the most direct and immediate path to
                    reducing our carbon footprint. However, we have also made
                    tremendous progress on our initial CCS project this year, a
                    collaborative effort between our Sustainable Power Group and
                    multiple divisions and disciplines.
                  </p>{" "}
                  <p>
                    We remain steadfast in our commitment to meaningful and
                    quantifiable sustainability goals, accompanied by a robust
                    plan of action to attain them. Our near-term targets are
                    what drives our decisions and pushes us to innovate and
                    pioneer solutions. We are currently reviewing both our
                    recent performance and future capabilities with each of our
                    operational areas and look forward to rolling out new
                    near-term and medium-term emissions intensity targets in
                    2024.
                  </p>{" "}
                  <p>
                    By remaining focused on our fundamental value proposition,
                    we believe we can successfully navigate the ever-evolving
                    landscape of the energy industry.{" "}
                    <strong>
                      <em>An Integrated Approach</em>
                    </strong>{" "}
                    drives the development and adoption of sustainable solutions
                    that meet the dual challenge of the energy transition -
                    supply growing global demand for energy reliably and
                    affordably, while reducing emissions to address climate
                    change.
                  </p>{" "}
                  <p>
                    I want to extend my sincerest appreciation to our employees.
                    It is through our collective efforts that we have been able
                    to make so much progress, so quickly. Their efforts,
                    combined with the trust and support of our stakeholders,
                    strengthen our resolve to be among the lowest-cost, lowest
                    emissions, and highest-return producers of oil and natural
                    gas, playing a significant role in the long-term future of
                    energy.
                  </p>
                  <p>
                    Sincerely,
                    <br />
                    <br />
                    <strong>Ezra Y. Yacob</strong>
                    <br />
                    Chairman of the Board and Chief Executive Officer
                    <br />
                    September 2023
                  </p>
                  <cite>
                    <sub>i</sub> Direct, well-level, after-tax rate of return,
                    ATROR. See related discussion in our 2022 Sustainability
                    Report.
                  </cite>
                </React.Fragment>
              )}
            </Column>
            <Column thirds={1}>
              <ul className="sustain-list">
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={EOG_2022_Sustainability}
                  >
                    <span className="icon">
                      <BookSVG />
                    </span>
                    2022 Sustainability Report
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={EOG_2022_Sustainability_TEAR_SHEET}
                  >
                    <span className="icon">
                      <DataSheet style={{ marginTop: 4 }} />
                    </span>
                    2022 Data Sheet
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={EOG_2022_Sustainability_Presentation}
                  >
                    <span className="icon">
                      <Presentation style={{ marginTop: 4 }} />
                    </span>
                    2022 ESG Presentation
                  </a>
                </li>
                <li>
                  <a target="_blank" rel="noreferrer" href={EOG_2022_EEO}>
                    <span className="icon">
                      <EEOIcon style={{ marginTop: 10 }} />
                    </span>
                    EEO-1 Report (as filed in December 2023)
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={TRADE_ASSOCIATION_MEMBERSHIP_DUES}
                  >
                    <span className="icon">
                      <img
                        src={MembershipIconImage}
                        style={{ marginTop: 10 }}
                        alt="Trade Association Memberships & Dues"
                      />
                    </span>
                    Trade Association Memberships & Dues
                  </a>
                </li>
                <li>
                  <a href={"/sustainability/archives"}>
                    <span className="icon">
                      <FontAwesomeIcon
                        icon={faArchive}
                        style={{ marginTop: 10, width: 50, height: 50 }}
                        className="default-icon"
                      />
                    </span>
                    Sustainability Report Archives
                  </a>
                </li>
              </ul>
            </Column>
          </Row>
        </section>
      </div>
      <SectionBar name="2022 Highlights" id="highlights" />
      <div className="max-width-container page sustainability-page">
        <section style={{ paddingBottom: "50px" }}>
          <Row>
            <Column>
              <img
                src={EnvironmentImage}
                alt="Environment"
                className="sustainability-callout-image no-top"
              />
            </Column>
            <Column>
              <h3>Piloting Carbon Capture and Storage</h3>
              <p>
                EOG piloted a CCS project to capture and store concentrated CO
                <sub>2</sub> emissions from the treating of our natural gas at a
                facility in Texas. The injection well was permitted and
                construction began in the summer of 2022 and initial CO
                <sub>2</sub> injection commenced in 2023.
              </p>

              <h3>Joined OGMP 2.0</h3>
              <p>
                In early 2023, EOG joined the Oil and Gas Methane Partnership
                (OGMP) 2.0, a comprehensive measurement-based reporting
                framework designed to improve the accuracy and transparency of
                methane emissions reporting in the oil and gas industry.
              </p>

              <h3>2022 Industry Wildlife Stewardship Award Recipient</h3>
              <p>
                EOG was recognized by the Wyoming Fish & Game Department for the
                positive impact our stewardship and conservation activities have
                had on wildlife and habitat in Wyoming.
              </p>
            </Column>
          </Row>
          <Row>
            {isMobile && (
              <Column>
                <img
                  src={SocialImage}
                  alt="Social"
                  className="sustainability-callout-image"
                />
              </Column>
            )}
            <Column>
              <p>
                EOG's culture drives employee engagement, satisfaction and
                performance by <strong>empowering</strong> employees as idea
                generators and decision makers; <strong>enabling</strong>{" "}
                innovation and continuous improvement with creative technology
                solutions and access to real-time performance data; and{" "}
                <strong>fostering</strong> collaboration through
                multidisciplinary teams across operating areas and cross-company
                communication channels.
              </p>
              <h3>Broad Recognition from Energage's Top Workplace</h3>
              <p>
                EOG continues to be recognized as a Top Workplace, a designation
                awarded to companies that create a positive work environment by
                prioritizing a people-centered culture and giving employees a
                voice.
              </p>
              <img src={TWP2023} alt="gptw 2023" />
            </Column>
            {!isMobile && (
              <Column>
                <img
                  src={SocialImage}
                  alt="Social"
                  className="sustainability-callout-image"
                />
              </Column>
            )}
          </Row>
          <Row>
            <Column>
              <img
                src={GovernanceImage}
                alt="Governance"
                className="sustainability-callout-image"
              />
            </Column>
            <Column>
              <p>
                EOG's strong corporate governance practices enhance board and
                management accountability to our shareholders and other
                stakeholders and enhance our risk oversight and management
                efforts.
              </p>
              <p>
                In 2022, the Compensation and Human Resources Committee again
                established a separately weighted ESG-related annual performance
                goal and increased the weighting to 10%. The goal included the
                following:
              </p>
              <ul className="disc">
                <li>
                  <strong>Reduce</strong> total recordable incident rate and oil
                  spill rates below the prior three-year averages
                </li>
                <li>
                  <strong>Maintain</strong> gas capture rate of 99.8% or higher
                </li>
                <li>
                  <strong>Reduce</strong> GHG, methane, and flaring emissions
                  intensity rates compared with 2021
                </li>
              </ul>

              <p>
                In 2023, the Compensation and Human Resources Committee
                increased the separately weighted ESG-related performance goal
                to 15%.
              </p>
            </Column>
          </Row>
        </section>
      </div>
      <div className="last-page" />
      <ScrollToTop />
    </Layout>
  )
}
