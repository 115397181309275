import * as React from "react"

function BookSVG(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={50} height={50} viewBox="0 0 51 53" {...props}>
      <defs>
        <path
          d="M38.318 17.323c2.858.433 5.58 1.381 8.162 2.846L50.286 51.4l-.46-.007c-1.985-.062-3.935-.526-5.85-1.393-3.274-1.49-6.49-2.234-9.65-2.234-3.7 0-6.76 1.211-9.183 3.634-2.423-2.423-5.484-3.634-9.183-3.634-3.16 0-6.376.745-9.65 2.234-2.095.933-4.157 1.4-6.187 1.4H0l3.806-31.232.46-.255a23.533 23.533 0 017.386-2.541c-.1.782-.152 1.571-.152 2.37 0 .368.015.734.044 1.096a20.949 20.949 0 00-4.62 1.49L3.88 47.4l.39-.16a109.12 109.12 0 013.674-1.436c.99-.36 2.206-.684 3.646-.97 1.44-.287 2.898-.43 4.37-.43 3.307 0 6.368.884 9.184 2.652 2.815-1.768 5.876-2.652 9.183-2.652 2.111 0 4.01.233 5.696.7 1.686.466 3.814 1.232 6.384 2.296l-3.044-25.07-.497-.218a20.952 20.952 0 00-4.405-1.326c.027-.343.04-.691.04-1.043 0-.721-.049-1.44-.144-2.158zm-3.673-.273c.194 0 .387.003.58.007.181.9.275 1.795.275 2.685 0 .236-.008.469-.023.7a23.13 23.13 0 00-1.151-.028c-3.667 0-6.728 1.154-9.183 3.462-2.456-2.308-5.517-3.462-9.183-3.462-.483 0-.962.015-1.435.043a10.008 10.008 0 01-.025-.715c0-.9.08-1.792.234-2.675l.38-.011.527-.006c3.814 0 6.981.868 9.502 2.603 2.52-1.735 5.688-2.603 9.502-2.603z"
          id="prefix__a"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g fillRule="nonzero" transform="translate(0 .742)">
          <use fill="#000" xlinkHref="#prefix__a" />
          <use fill="#33495F" xlinkHref="#prefix__a" />
        </g>
        <path
          stroke="#33495F"
          strokeWidth={3}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M25 22.693v15.074"
        />
        <path
          d="M37 20.546c0 6.736-5.372 12.196-12 12.196-6.627 0-12-5.46-12-12.196C13 8.833 25 .742 25 .742s12 9.044 12 19.804z"
          fill="#33495F"
        />
      </g>
    </svg>
  )
}

export default BookSVG
