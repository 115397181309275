import * as React from "react"

const SvgComponent = (props) => (
  <svg
    width={52}
    height={35}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m47.343 3.402 3.806 31.233c-2.144 0-4.248-.467-6.31-1.4-3.274-1.49-6.49-2.234-9.65-2.234-3.7 0-6.76 1.21-9.183 3.634C23.584 32.212 20.523 31 16.823 31c-3.16 0-6.376.744-9.65 2.234-2.095.933-4.157 1.4-6.187 1.4H.863L4.67 3.402C8.336 1.324 12.281.284 16.504.284c3.814 0 6.981.868 9.502 2.603C28.527 1.152 31.694.284 35.508.284c4.224 0 8.168 1.04 11.835 3.118ZM35.19 27.637c2.112 0 4.01.237 5.697.712 1.686.458 3.814 1.22 6.383 2.283L44.226 5.563c-2.8-1.277-5.811-1.915-9.036-1.915-3.666 0-6.727 1.154-9.183 3.462-2.455-2.308-5.516-3.462-9.183-3.462-3.225 0-6.237.638-9.036 1.915l-3.044 25.07c1.718-.704 3.069-1.236 4.051-1.597.999-.36 2.218-.679 3.659-.957a21.749 21.749 0 0 1 4.37-.442c3.307 0 6.368.884 9.183 2.652 2.816-1.768 5.877-2.652 9.183-2.652Zm.982-.835-1.35-22.27c-3.454.065-6.392 1.334-8.815 3.806-2.488-2.538-5.549-3.806-9.183-3.806-2.88 0-5.63.54-8.25 1.62L5.774 29.257c2.03-.835 3.896-1.457 5.598-1.866 1.719-.409 3.536-.614 5.451-.614 3.421 0 6.482.835 9.183 2.505 2.816-1.752 5.877-2.586 9.183-2.505l.982.025Z"
      fill="#33495F"
    />
  </svg>
)

export default SvgComponent
