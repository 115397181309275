import * as React from "react"

const SvgComponent = (props) => (
  <svg
    width={38}
    height={45}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M36.181 10.108c.459.458.852 1.08 1.179 1.866.327.786.491 1.506.491 2.16V42.42c0 .655-.23 1.212-.688 1.67a2.273 2.273 0 0 1-1.67.687h-33c-.654 0-1.21-.229-1.669-.687a2.273 2.273 0 0 1-.687-1.67V3.135c0-.655.229-1.212.687-1.67a2.273 2.273 0 0 1 1.67-.688h22c.655 0 1.375.164 2.16.491.786.328 1.408.72 1.867 1.179l7.66 7.66ZM25.28 4.117v9.232h9.232c-.164-.475-.344-.81-.54-1.007l-7.686-7.685c-.196-.197-.532-.377-1.006-.54Zm9.428 37.518V16.492H24.494c-.655 0-1.211-.23-1.67-.688a2.273 2.273 0 0 1-.687-1.67V3.92H3.28v37.715h31.428ZM9.565 20.42c0-.229.074-.417.221-.565a.765.765 0 0 1 .565-.22h17.286c.229 0 .417.073.564.22.148.148.221.336.221.565v1.572c0 .229-.073.417-.22.564a.765.765 0 0 1-.565.221H10.35a.764.764 0 0 1-.565-.22.765.765 0 0 1-.22-.565V20.42Zm18.072 5.5c.229 0 .417.074.564.221.148.148.221.336.221.565v1.571c0 .23-.073.418-.22.565a.765.765 0 0 1-.565.221H10.35a.764.764 0 0 1-.565-.22.765.765 0 0 1-.22-.566v-1.571c0-.23.073-.417.22-.565a.764.764 0 0 1 .565-.22h17.286Zm0 6.286c.229 0 .417.074.564.221.148.147.221.336.221.565v1.571c0 .23-.073.417-.22.565a.765.765 0 0 1-.565.22H10.35a.765.765 0 0 1-.565-.22.765.765 0 0 1-.22-.565v-1.571c0-.23.073-.418.22-.565a.764.764 0 0 1 .565-.221h17.286Z"
      fill="#33495F"
    />
  </svg>
)

export default SvgComponent
