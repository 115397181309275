import * as React from "react"

const SvgComponent = props => (
  <svg
    width="50"
    height="51"
    viewBox="0 0 50 51"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M23.7509 1.25V3.75011H1.25C0.559644 3.75011 0 4.30976 0 5.00011V6.25011C0 6.94017 0.559159 7.49963 1.2491 7.50011V37.5C0.55916 37.5005 0 38.0599 0 38.75V40C0 40.6904 0.559643 41.25 1.25 41.25H14.7514L11.7157 48.8393C11.4593 49.4803 11.771 50.2077 12.412 50.4641C13.053 50.7205 13.7805 50.4087 14.0369 49.7678L17.444 41.25H33.8096L37.2167 49.7678C37.4731 50.4087 38.2006 50.7205 38.8416 50.4641C39.4825 50.2077 39.7943 49.4803 39.5379 48.8393L36.5022 41.25H48.75C49.4404 41.25 50 40.6904 50 40V38.75C50 38.0596 49.4404 37.5 48.75 37.5H48.7491V7.50011H48.75C49.4404 7.50011 50 6.94047 50 6.25011V5.00011C50 4.30976 49.4404 3.75011 48.75 3.75011H26.2509V1.25C26.2509 0.559644 25.6913 0 25.0009 0C24.3105 0 23.7509 0.559644 23.7509 1.25ZM3.7491 8.75V36.25H46.2491V8.75H3.7491ZM41.2449 17.5729C41.2852 17.2301 41.04 16.9195 40.6972 16.8792L35.1107 16.2219C34.7679 16.1816 34.4573 16.4268 34.417 16.7696C34.3766 17.1124 34.6219 17.423 34.9647 17.4634L39.0526 17.9443L28.7821 26.0526L20.0584 17.952L7.77123 26.3591C7.48636 26.554 7.41343 26.9429 7.60835 27.2278C7.80326 27.5127 8.19221 27.5856 8.47709 27.3907L19.9399 19.5477L28.7162 27.6972L39.8271 18.9254L39.3462 23.0133C39.3059 23.3562 39.5511 23.6668 39.8939 23.7071C40.2367 23.7474 40.5473 23.5022 40.5877 23.1594L41.2449 17.5729Z"
      fill="#33495F"
    />
  </svg>
)

export default SvgComponent
