import React from "react"

export const Row = ({ children, className = "" }) => (
  <div className={`max-width-container  sustainability-row ${className}`}>
    {children}
  </div>
)

export const Column = ({ children, wide = false, thirds = 0 }) => (
  <div
    className={`sustainability-column ${wide === true ? "wide" : ""} ${thirds >
      0 && `thirds-${thirds}`}`}
  >
    {children}
  </div>
)

export const SectionBar = ({ name, id, color = "#38AF32" }) => (
  <div
    className="sustainability-title-bar"
    id={id}
    style={{ backgroundColor: color }}
  >
    {name}
  </div>
)
